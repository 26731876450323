@media (max-width:1499px) {
  .delete-member {
    width: 55% !important;
  }
}

@media (max-width:1299px) {

  .tasks .card,
  .going-card {
    width: 100% !important;
  }
}

@media (max-width:1199px) {
  .setting-media {
    width: calc(100% / 1) !important;
  }

  .setting-media textarea {
    max-width: 100%;
  }

  .pseudo-time {
    width: 20% !important;
  }

  .time-line-task {
    width: 80% !important;
  }

  .layout {
    display: none !important;
  }
}


@media (max-width: 991px) {


  section table tr td img {
    display: none;
  }

  section table tbody tr td:nth-child(4),
  section table thead tr td:nth-child(4),
  section table tbody tr td:nth-child(5),
  section table thead tr td:nth-child(5),
  section table tbody tr td:nth-child(6),
  section table thead tr td:nth-child(6) {
    display: none;
  }

  .delete-member {
    width: 85% !important;
  }

}

@media (max-width: 768px) {

  .page-sections {
    width: calc(100% - 50px) !important;
    margin-left: 50px !important;
  }

  .nav-wrapper {
    width: 50px !important;
    padding: 5px !important;
    position: fixed;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .project-wrapper>div>h1 {
    font-size: 20px;
  }

  .going-card h3 {
    font-size: 15px !important;
  }

  .going-card>div div:last-child {
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
  }

  .going-card>div div:last-child button {
    margin: 10px 0 !important;
  }

  .table select {
    margin-bottom: 5px !important;
  }

  table {
    font-size: 9px !important;
  }

  .delete-member {
    width: 90% !important;
  }



  .person .person-info h3,
  .person .person-info p {
    font-size: 11px !important;
    margin-bottom: 0px !important;
  }

  .person .person-info h3 {
    margin-bottom: 5px !important;
  }

  .person-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .person-btns div {
    margin: 0 !important;
  }

  .person-btns div:first-child {
    margin-bottom: 10px !important;
  }

  .pseudo-time {
    display: none;
  }

  .time-line-task p,
  .time-line-task h4 {
    font-size: 12px !important;
    padding: 10px !important;
  }

  .time-line-task {
    width: 100% !important;
  }

}

@media (max-width: 550px) {
  .card h2 {
    font-size: 13px !important;
  }

  .card>p {
    font-size: 11px !important;
    max-width: 100% !important;
  }

  .delete-member {
    width: 98% !important;
  }

  .delete-member div:last-child {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .delete-member div:last-child>div {
    margin: 0 !important;
  }

  .delete-member div:last-child>div:first-child {
    margin-bottom: 5px !important;
  }

  .users-control>div:nth-child(2) div p,
  .users-control>div:nth-child(2) div div {
    font-size: 13px !important;
  }

  .users-control>div:nth-child(2) div div:nth-child(2) div {
    display: none;
  }

  .days-wrapper,
  .days {
    font-size: 11px;
  }

  .days span {
    width: 20px;
    height: 20px;
  }

  .size p {
    font-size: 12px;
  }

  .size p:nth-child(2),
  .size svg {
    display: none;
  }
}