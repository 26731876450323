* {
  padding: 0;
  margin: 0 !important;
  box-sizing: border-box;
  text-decoration: none !important;
  list-style: none !important;
}


body {
  font-family: 'Almarai', sans-serif;
  font-family: 'Cairo', sans-serif;
  font-family: 'IBM Plex Serif', serif;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden !important;
}


:root {
  --bg-color: black;
  --text-color: white;
  --main-color: #39393987;
  --second-color: #393939;
  --light-color: #393939;
  --off-dark: #1a1c20;
  --off-white: #f9f9f9;
  --near-to-yellow: #f6dc87;
  --box-shadow: 0px 0 9px 3px #27292d;
  --trasnition: 0.5s;
}

body.light-mode {
  --bg-color: #f1f5fb;
  --text-color: black;
  --main-color: #ffffff;
  --second-color: white;
  --off-dark: white;
  --light-color: #f1f5fb;
}

/* 
  -- Scrool Bar
*/
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: var(--second-color);
}

::-webkit-scrollbar-thumb {
  background-color: #6a6a6a;
}

.container {
  max-width: 100%;
}

/* 
  -- header
*/
.nav-link:hover {
  -webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-contract 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

/* 
  -- Gloable Classes
*/

.page-sections {
  min-height: 100vh;
  width: calc(100% - 300px) !important;
}

.d-flex-between {
  justify-content: space-between;
  align-items: center;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  visibility: visible !important;
  opacity: 1 !important;
  transform: scale(1) !important;
}

.active_toggle_btn {
  background-color: #0362ff;
  color: white !important;
}

.active_scale_with_right {
  visibility: visible !important;
  opacity: 1 !important;
  right: 0 !important;
  transform: translate(0%, 0%) scale(1) !important;
}

.scale_controal {
  transform: translate(-50%, -50%) scale(1) !important;
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 100;
}

.main-btn {
  border: none;
  outline: none;
  color: var(--text-color);
  padding: 5px 10px !important;
  border-radius: 5px;
  background-color: var(--bg-color);
  cursor: pointer;
  font-weight: bold;
}

/* 
  -- Home Page
*/

.addNewTask {
  transition: var(--trasnition);
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  transform: scale(0);
  width: 100% !important;
  background-color: var(--light-color);
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  right: -1500px;
}

.addNewTask .close {
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-weight: bold;
  background-color: #404148;
}

.lvl {
  font-size: 12px;
  padding: 2px 10px !important;
  text-align: center;
  border-radius: 5px;
}

.high {
  background-color: #ff00005c;
  color: red;
}

.medium {
  background-color: #ffff0045;
  color: black;
}

.low {
  background-color: #00800052;
  color: green;
}

.card.dragging {
  opacity: 0.8;
}

/* 
  -- Navbar
*/


.nav-wrapper {
  width: 300px;
  padding: 20px !important;
  overflow: hidden;
  transition: all 0.3s ease;
}

.nav-wrapper .logo::after {
  content: "";
  background-color: white;
  width: 50%;
  height: 2px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.nav-wrapper .logo::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  z-index: 10;
}

.nav-wrapper .logo {
  width: 100%;
  margin-bottom: 30px !important;
}

.nav-wrapper ul {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link.active {
  font-weight: bolder !important;
  color: white !important;
}

.link-item a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 16px;
  white-space: nowrap;
  text-transform: capitalize;
}

.link-item a span {
  transition: transform 0.5s;
}

.link-item i {
  min-width: 20px;
  min-height: 20px;
  margin-right: 20px !important;
}

/* 
  -- Setting Page
*/


.setting-media.active-border {
  border: 2px solid #0075ff;
}

.setting-nav {
  position: relative;
}

.setting-nav.active_Item::after {
  content: "";
  position: absolute;
  bottom: -1.5px;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #0075ff;
}

.resend-message {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}


.toggle-checkbox {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.toggle-switch {
  background-color: #ccc;
  width: 78px;
  height: 32px;
  border-radius: 16px;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
}

.toggle-switch::before {
  font-family: var(--fa-style-family-classic);
  content: "\f00d";
  font-weight: 900;
  background-color: white;
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  transition: 0.3s;
}

.toggle-checkbox:checked+.toggle-switch {
  background-color: blue;
}

.toggle-checkbox:checked+.toggle-switch::before {
  content: "\f00c";
  left: 50px;
  color: blue;
}

/* 
  -- radio box
*/

.backup-control input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
}

.backup-control .date label {
  padding-left: 30px;
  cursor: pointer;
  position: relative;
}

.backup-control .date label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  margin-top: -11px;
  width: 18px;
  height: 18px;
  border: 2px solid white;
  border-radius: 50%;
}

.backup-control .date label::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 5px;
  width: 10px;
  height: 10px;
  background: #0075ff;
  border-radius: 50%;
  transition: 0.3s;
  transform: scale(0);
}

.backup-control .date input[type="radio"]:checked+label::before {
  border-color: #0075ff;
}

.backup-control .date input[type="radio"]:checked+label::after {
  transform: scale(1);
}

/* 
  -- Calendar Page
*/

.prevLastDays,
.nextDays {
  opacity: 0.4;
}

.month-day {
  display: inline-block;
  padding: 5px;
}

.current_day {
  height: 30px;
  width: 30px;
  background-color: var(--bs-link-color);
  color: white;
  border-radius: 50%;
  margin: 0 auto !important;
}

.pseudo-time::after {
  content: "";
  height: calc(100% - 20px);
  width: 0;
  border: 1px dashed var(--bs-link-color);
  position: absolute;
  right: 15px;
  top: 20px;
}

.pseudo-time::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--bs-link-color);
  border-radius: 50%;
  position: absolute;
  right: 11px;
  top: 5px;
}

.time-line-task {
  cursor: pointer;
}

.time-line-task.active_task {
  background-color: #2f333c !important;
}


.full-chat h6::after {
  content: "";
  width: 35%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #555;
}

.full-chat h6::before {
  content: "";
  width: 35%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 65%;
  background-color: #555;
}

/* 
  -- Swiper Styling
*/

.swiper {
  margin: 20px 0 !important;
  width: 100%;
  padding: 10px 0 10px 20px;
}

.swiper-slide {
  display: flex;
  width: 35px !important;
  margin-left: 20px !important;
}

/* 
  -- Loader
*/

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

/* 
  -- Table
*/

.project-wrapper img:not(:first-child) {
  margin-left: -5px !important;
}

table tbody tr td img {
  margin-right: 10px !important;
}

/* 
  -- Animation
*/

@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}